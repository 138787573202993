const HOUR = 60;
const RADIX = 10;

const pad = (n: number) => n < 10 ? `0${n}` : n;

export class TimeWithTZ {
  /**
   * Milliseconds since epoch. This is the actual time value, which could be
   * localized to any time zone if needed.
   */
  utc: number;

  /**
   * The time zone offset in minutes which was provided with the timestamp,
   * which can be used to restore the original localized time.
   */
  tzOffset: number;

  /**
   * utc: Time in milliseconds since epoch. See the utc property.
   * tzOffset: Target time zone in minutes. See the tzOffset property.
   */
  constructor(utc: number, tzOffset: number) {
    this.utc = utc;
    this.tzOffset = tzOffset;
  }

  /**
   * Parses '/Date(<ms since epoch><time zone>)/' formats and returns an
   * instance of the class, if successful.
   */
  static parse(timestamp: string): TimeWithTZ | null {
    const pattern = /^\/Date\((\d+)(?:([+-]\d{2})(\d{2}))?\)\/$/;
    const result = pattern.exec(timestamp);

    if (result) {
      const utc = parseInt(result[1], RADIX);
      const tzOffset = result.length > 2 ? parseInt(result[2], RADIX) * HOUR + parseInt(result[3], RADIX) : 0;
      return new TimeWithTZ(utc, tzOffset);
    }

    return null;
  }

  /**
   * Returns an ISO 8601 timestamp looking like 'YYYY-mm-ddTHH:MM:SS+ZZ:ZZ'.
   * Milliseconds are ignored.
   */
  toModernPSAPIFormat(): string {
    const date = new Date(this.utc + this.tzOffset * 60000);
    return [
      date.getUTCFullYear(),
      '-', pad(date.getUTCMonth() + 1),
      '-', pad(date.getUTCDate()),
      'T', pad(date.getUTCHours()),
      ':', pad(date.getUTCMinutes()),
      ':', pad(date.getUTCSeconds()),
      this.tzOffset < 0 ? '-' : '+',
      pad(Math.floor(this.tzOffset / HOUR)),
      ':', pad(this.tzOffset % HOUR)].join('');
  }

  /**
   * Returns a compact date format (YYYMMDD).
   */
  toCompactLocalDate(): string {
    const date = new Date(this.utc + this.tzOffset * 60000);
    return [date.getUTCFullYear(), pad(date.getUTCMonth()), pad(date.getUTCDate())].join('');
  }
}
