import bows from 'bows';
import EventEmitter from 'eventemitter3';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import NrkEvents from '../NrkEvents';
import { PLAYING_LIVE_DURATION } from '../../ui/components/liveButton/index';

import defaultVendorSpringstreams from '@nrk/media-analytics/dist/vendor/scores/springstreams';
import {
  Adapter as AnalyticsAdapter,
  EventArgs as AnalyticsEventArgs,
  Events as AnalyticsEvents,
  VendorConfig,
  VendorData,
  VendorType,
  VendorTypeMap,
  mediaTracker as defaultMediaTracker
} from '@nrk/media-analytics';
import { ExtendedLudo, SnowplowConfig } from '../../ludo/interfaces';
import type { Snowplow } from '@nrk/snowplow-web';
import type { EPGEntry } from '../LiveEpg/types';

function getAnalyticsEmitter(player: ExtendedLudo) {
  const emitter = new EventEmitter<AnalyticsEventArgs>();
  let isAttacheded = false;

  function forwardEvent(
    analyticsEvent: Exclude<AnalyticsEvents, AnalyticsEvents.BITRATESWITCH | AnalyticsEvents.PROGRAM_CHANGED>
  ) {
    return () => emitter.emit(analyticsEvent);
  }

  let itemChanged = false;

  const ludoToAnalyticsEventMapping = {
    [LudoEvents.PREPARATORSCOMPLETE]: forwardEvent(AnalyticsEvents.INTENT_TO_PLAY),
    [LudoEvents.PLAYING]: forwardEvent(AnalyticsEvents.PLAYING),
    [LudoEvents.PAUSE]: forwardEvent(AnalyticsEvents.PAUSE),
    [LudoEvents.SEEKED]: forwardEvent(AnalyticsEvents.SEEKED),
    [LudoEvents.SEEKING]: forwardEvent(AnalyticsEvents.SEEKING),
    [LudoEvents.BUFFERSTART]: forwardEvent(AnalyticsEvents.BUFFERSTART),
    [LudoEvents.BUFFEREND]: forwardEvent(AnalyticsEvents.BUFFEREND),
    [LudoEvents.TIMEUPDATE]: (time: number) => {
      emitter.emit(AnalyticsEvents.TIMEUPDATE, time);
    },
    [LudoEvents.ERROR]: (event: string, data: any) => {
      if (data.fatal) {
        emitter.emit(AnalyticsEvents.ERROR);
      }
    },
    [LudoEvents.ENDED]: () => {
      forwardEvent(AnalyticsEvents.ENDED)();
      player.once(LudoEvents.PLAY, () => {
        emitter.emit(AnalyticsEvents.INTENT_TO_PLAY);
      });
    },
    [LudoEvents.ITEM_CHANGED]: () => {
      // Do not emit unload for first item
      if (!itemChanged) {
        itemChanged = true;
        return;
      }
      emitter.emit(AnalyticsEvents.UNLOAD);
      player.once(LudoEvents.PREPARED, () => {
        emitter.emit(AnalyticsEvents.INTENT_TO_PLAY);
      });
    },
    [LudoEvents.UNLOADED]: () => {
      emitter.emit(AnalyticsEvents.UNLOAD);
    },
    [LudoEvents.QUALITY_SWITCHED]: (quality: { bitrate?: number }) => {
      const { bitrate } = quality;
      if (bitrate) {
        emitter.emit(AnalyticsEvents.BITRATESWITCH, bitrate);
      }
    },
    [NrkEvents.LIVEPROGRAM_CHANGED]: (data: EPGEntry | null) => {
      emitter.emit(
        AnalyticsEvents.PROGRAM_CHANGED,
        data ? { id: data.programId, utcStart: data.actualStartUTC / 1000, utcEnd: data.actualEndUTC / 1000 } : null
      );
    }
  };

  const beforeUnload = () => emitter.emit(AnalyticsEvents.UNLOAD);

  function attachForwardEvents() {
    if (isAttacheded) {
      return;
    }
    Object.keys(ludoToAnalyticsEventMapping).forEach((ludoEvent) => {
      const forwardFunc = ludoToAnalyticsEventMapping[ludoEvent];
      player.on(ludoEvent, forwardFunc);
    });
    window.addEventListener('beforeunload', beforeUnload);
    window.addEventListener('pagehide', beforeUnload);
    isAttacheded = true;
  }

  function detachForwardEvents() {
    if (!isAttacheded) {
      return;
    }
    Object.keys(ludoToAnalyticsEventMapping).forEach((ludoEvent) => {
      const forwardFunc = ludoToAnalyticsEventMapping[ludoEvent];
      player.off(ludoEvent, forwardFunc);
    });
    window.removeEventListener('beforeunload', beforeUnload);
    window.removeEventListener('pagehide', beforeUnload);
    isAttacheded = false;
  }

  return {
    emitter,
    attachForwardEvents,
    detachForwardEvents
  };
}

export default function nrkMediaLogger(
  player: ExtendedLudo,
  container: {
    snowplow: Snowplow;
    snowplowConfig: SnowplowConfig;
    userId?: string;
    debug?: typeof bows;
    SpringStreams?: typeof defaultVendorSpringstreams;
    mediaTracker?: typeof defaultMediaTracker;
  }
) {
  const {
    snowplow,
    snowplowConfig,
    userId,
    debug = bows,
    SpringStreams = defaultVendorSpringstreams,
    mediaTracker = defaultMediaTracker
  } = container;

  // vendors
  const vendors: VendorTypeMap = {
    [VendorType.SCORES]: SpringStreams,
    [VendorType.SNOWPLOW]: snowplow
  };

  // emitter
  const { emitter, attachForwardEvents, detachForwardEvents } = getAnalyticsEmitter(player);

  // data
  const data: VendorData = {
    [VendorType.SNOWPLOW]: {
      config: {
        ...snowplowConfig,
        useSharedWebPageContextPlugin: true
      }
    },
    // @ts-expect-error
    [VendorType.SCORES]: {}
  };

  let isLive: boolean;
  let channelId: string | undefined;
  let gotPlayingEvent = false;

  player.on(LudoEvents.PREPARED, () => {
    const mediaItem = player.current()!;
    if (mediaItem.requireManifestLoad) {
      return;
    }

    data[VendorType.SCORES] = mediaItem.scoresStats;
    data[VendorType.SNOWPLOW]!.content = {
      id: mediaItem.id,
      source: mediaItem.snowplowStats?.source
    };
    data[VendorType.SNOWPLOW]!.userId = userId;

    isLive = mediaItem.isLive;
    channelId = mediaItem.isChannel ? mediaItem.id : undefined;

    emitter.emit(AnalyticsEvents.DATA_CHANGED);
  });

  player.on(LudoEvents.LOADED, () => {
    const mediaItem = player.current()!;
    gotPlayingEvent = false;

    // For channels, delay emitting loaded event to media-tracker until first
    // liveprogram-changed event. The springStreamProgramId must be set before
    // LOADED is emitted.
    if (mediaItem.isChannel) {
      let timeoutId: number | undefined;

      const emitAfterEvent = (program?: EPGEntry) => {
        window.clearTimeout(timeoutId);
        timeoutId = undefined;
        emitLoadedOnLiveProgramChanged(program);
      };

      const emitAfterTimeout = () => {
        player.off(NrkEvents.LIVEPROGRAM_CHANGED, emitAfterEvent);
        timeoutId = undefined;
        emitLoadedOnLiveProgramChanged();
      };

      timeoutId = window.setTimeout(emitAfterTimeout, 5000);
      player.once(NrkEvents.LIVEPROGRAM_CHANGED, emitAfterEvent);

      return;
    }

    // OnDemand streams need the duration ready before media-analytics can be
    // LOADED. The quantile tracking depends on the duration.
    if (!mediaItem.isLive && !player.duration()) {
      const emitOnDuration = (duration: number) => {
        if (duration) {
          player.off(LudoEvents.DURATIONCHANGE, emitOnDuration);
          emitLoaded();
        }
      };

      player.on(LudoEvents.DURATIONCHANGE, emitOnDuration);
      return;
    }

    const mediaElement: HTMLMediaElement = player.get(LudoOptions.VIDEO_ELEMENT);
    if (mediaElement) {
      if (mediaElement.readyState < 1) {
        const emitOnMetadataLoaded = () => {
          mediaElement.removeEventListener('loadedmetadata', emitOnMetadataLoaded);
          emitLoaded();
        };
        mediaElement.addEventListener('loadedmetadata', emitOnMetadataLoaded);
        return;
      }
    }

    emitter.emit(AnalyticsEvents.LOADED);
  });

  player.on(LudoEvents.PLAYING, () => {
    gotPlayingEvent = true;
  });

  function emitLoadedOnLiveProgramChanged(program?: EPGEntry) {
    const mediaItem = player.current()!;

    data[VendorType.SCORES] = {
      ...mediaItem.scoresStats,
      springStreamProgramId: program?.programId ?? null
    };

    emitter.emit(AnalyticsEvents.DATA_CHANGED);
    emitLoaded();
  }

  /*
   * Emit LOADED, and if already playing, emit PLAYING as well. For use when
   * the LOADED event needs to wait for something else to happen first (see
   * the LOADED event handler above), and is now ready to be emitted. The
   * PLAYING event might have already happened, and so needs to be emitted too.
   *
   * For example, the Samsung Internet browser provides the duration of
   * on-demand streams in the DURATIONCHANGE event that follows the PLAYING
   * event. So for the duration to be used by media-analytics, LOADED must await
   * the DURATIONCHANGE event that provides the duration, and then playing
   * actually starts and can be emitted as well.
   */
  function emitLoaded() {
    emitter.emit(AnalyticsEvents.LOADED);

    // player.isPaused() doesn't cover the case when the player is starting up
    // but isn't playing yet. Hence the extra "gotPlayingEvent".
    if (gotPlayingEvent && !player.isPaused()) {
      emitter.emit(AnalyticsEvents.PLAYING);
    }
  }

  // config
  const getConfig = <T extends VendorType>(type: T): VendorConfig<T> => {
    return {
      playerName: 'NRK Ludo player',
      playerVersion: player.get('version'),
      getLogger: () => debug(`ludo:mTr:${type.toLowerCase()}`),
      getVendor: () => vendors[type],
      getData: () => data[type]
    };
  };

  function getPosition() {
    if (isLive) {
      return player.currentLiveTime().getTime() / 1000;
    }
    return player.currentTime();
  }

  function getDuration() {
    if (isLive) {
      return player.convertTimeToLiveTime(player.duration()).getTime() / 1000;
    }
    return player.duration();
  }

  function isWatchingLive() {
    return isLive && player.currentTime() > player.duration() - PLAYING_LIVE_DURATION;
  }

  // adapter
  const adapter: AnalyticsAdapter = {
    getPosition,
    getDuration,
    getConfig,
    getChannelId: () => channelId,
    getLiveTimeAtPosZero: () => player.convertTimeToLiveTime(0).getTime() / 1000,
    isWatchingLive,
    isLiveStream: () => isLive,
    isChannel: () => channelId !== undefined
  };

  mediaTracker(window, emitter, adapter);

  player.on(LudoEvents.ADAPTER, () => {
    const adapterName = player.adapterName();
    if (adapterName === 'LudoCastPlayerAdapter') {
      detachForwardEvents();
      emitter.removeAllListeners();
    }
  });

  attachForwardEvents();
}
