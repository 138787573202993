import { nrkHelp } from '@nrk/core-icons';
import overlay from '../overlay';
import uiEvents from '../../ui/events';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  const helpButton = ui.element.querySelector<HTMLButtonElement>('button[name="help"]')!;
  const helpOverlayContent = ui.element.querySelector<HTMLElement>('.ludo-help')!;
  const helpOverlay: ReturnType<typeof overlay> = overlay(ui);

  helpButton.innerHTML = nrkHelp;
  helpOverlayContent.innerHTML = helpOverlayContent.innerHTML.replace('--version--', ui.get('version') as string);
  helpOverlayContent.removeAttribute('hidden');
  helpOverlay.content(helpOverlayContent);
  helpOverlay.on('closed', () => helpButton.focus({ preventScroll: true }));

  let linkTrackingInitialized = false;

  return (player: ExtendedLudo) => {
    helpButton.addEventListener('click', () => ui.emit(uiEvents.TOGGLEHELPOVERLAY));
    ui.on(uiEvents.TOGGLEHELPOVERLAY, () => {
      if (!linkTrackingInitialized) {
        linkTrackingInitialized = true;
      }

      const isVisible = helpOverlay.toggle();

      if (isVisible) {
        player.pause();
      }
    });
  };
};
