import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { SkipOverlay } from './SkipOverlay';
import { NrkEvents } from '../../../nrk';
import uiEvents from '../../ui/events';
import { PlayerDimensions } from '../../ui/playerDimensions';
import { SkipSectionChangedData, SkipSectionChangedEvent } from '../../../ludo/skip/detectSkipSections';
import uiOptions from '../../ui/options';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { PublicEvents } from '../../../publicEventsApi';
import { userPrefersReducedMotion } from '../../dom/reducedMotion';

export interface SkipOverlayOptions {
  skipIntro?: boolean;
  skipCredits?: boolean;
}

export default (ui: LudoUIType, options: SkipOverlayOptions) => {
  require('./skip-overlay.styl');

  const { skipIntro, skipCredits } = options;
  const skipOverlay = new SkipOverlay(ui.element, { skipIntro, skipCredits });
  let isSkipCreditsCancelled = false;

  const disableControls = () => ui.disableControls();
  const enableControls = () => {
    // set focus back to ludo
    if (skipOverlay.ludoContainsActiveElement()) {
      ui.element.tabIndex = -1;
      ui.element.focus();
    }
    // add timeout when enabling to avoid showing controls
    // because of mouse movement when clicking btn
    ui.hideControls();
    setTimeout(() => ui.enableControls(), 250);
  };

  return (player: ExtendedLudo) => {
    const overlayElement = skipOverlay.element;

    const dismissOverlay = () => {
      enableControls();
      if (skipOverlay.isVisible('credits')) {
        player.emit(PublicEvents.CANCEL_SKIP_TO_NEXT);
      }
      skipOverlay.hide();
    };

    skipOverlay.cancelBtn.addEventListener('click', dismissOverlay);

    overlayElement.addEventListener('click', (e) => {
      if (!skipOverlay.isVisible()) {
        return;
      }
      const target = e.target;
      if (target && target instanceof HTMLDivElement && target.classList.contains('ludo-skipoverlay')) {
        dismissOverlay();
      }
      if (target && target instanceof HTMLButtonElement) {
        switch (target.name) {
          case 'cancel':
            dismissOverlay();
            break;
          case 'ludo-skip-intro':
            enableControls();
            skipOverlay.hide();
            const skipIntroSpecification = player.current()?.interactionPoints?.intro;
            if (skipIntroSpecification?.type === 'seekToPoint') {
              player.seekTo(skipIntroSpecification.seekToTime);
              player.emit(NrkEvents.SKIP_INTRO_CLICKED);
            }
            break;

          case 'ludo-skip-credits':
            enableControls();
            skipOverlay.hide();
            player.emit(PublicEvents.SKIP_TO_NEXT);
            player.emit(NrkEvents.SKIP_CREDITS_CLICKED);
            break;
        }
      }
    });

    ui.element.addEventListener('keydown', (keyEvent: KeyboardEvent) => {
      const keyCode = keyEvent.which;

      if (!skipOverlay.isVisible()) {
        return;
      }

      switch (keyCode) {
        case 27: // ESCAPE
          keyEvent.stopPropagation();
          dismissOverlay();
          break;

        case 39: // RIGHT ARROW
          keyEvent.stopPropagation();
          skipOverlay.clickActiveButton();
          break;
      }
    });

    let controlsAreVisible = false;
    ui.on(uiEvents.CONTROLLERON, () => controlsAreVisible = true);
    ui.on(uiEvents.CONTROLLEROFF, () => controlsAreVisible = false);
    const waitForControlsHidden = (cb: () => void) => {
      if (controlsAreVisible) {
        ui.once(uiEvents.CONTROLLEROFF, cb);
      } else {
        cb();
      }
    };

    ui.on(SkipSectionChangedEvent, (data: SkipSectionChangedData) => {
      if (!data) {
        return;
      }
      const { state, section } = data;

      if (state === 'on') {
        skipOverlay.element.classList.add('ludo-skipoverlay--hidden');
        waitForControlsHidden(() => {
          disableControls(); // prevent display controls
          skipOverlay.element.classList.remove('ludo-skipoverlay--hidden');
          skipOverlay.toggle(section, state); // trigger focus if waited for controls hidden
        });
      } else {
        const ignoreTimeout = state === 'timedout' && userPrefersReducedMotion();
        if (!ignoreTimeout) {
          enableControls();
        }
      }
      skipOverlay.toggle(section, state);
    });

    // keep track of if skip credits has been cancelled
    player.on(LudoEvents.PLAYBACKSTARTED, () => {
      isSkipCreditsCancelled = false;
      const skipIntroSpecification = player.current()?.interactionPoints?.intro;
      if (skipIntroSpecification?.type === 'seekToPoint') {
        skipOverlay.setSectionButtonText('intro', skipIntroSpecification.displayValue);
      }
    });
    player.on(PublicEvents.CANCEL_SKIP_TO_NEXT, () => {
      isSkipCreditsCancelled = true;
    });
    player.on(LudoEvents.ENDED, () => {
      enableControls();
      skipOverlay.hide();
      // if users scrubs past end-time for skip credits
      // but feature is enabled and not cancelled, emit event
      if (skipCredits) {
        if (isSkipCreditsCancelled === false) {
          player.emit(PublicEvents.SKIP_TO_NEXT);
        // leave fullscreen if user cancelled skip credits
        } else if (player.get(LudoOptions.FULLSCREEN)) {
          player.fullscreen(false);
        }
      }
    });

    scaleFontSize(overlayElement, ui);
  };
};

function scaleFontSize(overlayElement: HTMLElement, ui: LudoUIType) {
  function setFontSize(dimensions?: PlayerDimensions) {
    if (!dimensions) {
      return;
    }
    overlayElement.style.fontSize = dimensions.getRemFontSize({ scale: 0.75, minPx: 12, maxPx: 16 });
  }

  ui.on(uiEvents.PLAYER_SIZE_CHANGED, setFontSize);
  setFontSize(ui.get(uiOptions.PLAYER_DIMENSIONS));
}
