import { LudoMediaItem } from '@nrk/ludo-core';

export interface Subtitle {
  src: string;
  srclang: string;
  label: string;
  default: boolean;
  type: string;
}

export interface IndexPoint {
  title: string;
  startPoint: string;
}

export interface Poster {
  src: string;
  width: number;
}

export interface Thumbnail {
  src: string;
  trigger: string;
  cols: number;
  frameDuration: number;
  width: number;
  height: number;
}

export interface LegalAge {
  code?: string;
  badge?: string;
  text?: string;
}

export interface Stream {
  id: string;
  label: string;
  href: string;
  value: string;
}

export interface SkipSpecification {
  startIntroInSeconds: number;
  endIntroInSeconds: number;
  startCreditsInSeconds: number;
}

export interface InteractionPoints {
  intro?: {
    type: 'seekToPoint';
    displayValue: string;
    startTime: number;
    endTime: number;
    seekToTime: number;
  };
  nextUp?: {
    type: 'nextUpPoint';
    startTime: number;
    endTime: number;
  };
}

export interface MediaItem extends LudoMediaItem {
  availableFrom?: Date;
  availableTo?: Date;
  duration: number;
  id: string;
  indexPoints: IndexPoint[];
  isAudio: boolean;
  isChannel: boolean;
  isOngoing: boolean;
  legalAge?: LegalAge;
  manifestName: string;
  poster?: string;
  posters: Poster[];
  programRightsHasNotStarted: boolean;
  scoresStats?: any;
  streams: Stream[];
  subtitle?: string;
  subtitles: Subtitle[];
  thumbnails: Thumbnail[];
  interactionPoints?: InteractionPoints;
  hasNextItem?: boolean;
  epgLiveBufferPath?: string;
  qoeStats?: {
    cdnName: string;
    clientName: string;
    correlationId: string;
  };
  snowplowStats?: {
    source: string;
  }
}

export const DEFAULT_MANIFEST_NAME = 'default';
