import { parse } from './parse';
import NrkEvents from '../NrkEvents';
import logging from 'bows';
import { EPGLiveBufferLoader } from '../APIClient/psapi/EPGLiveBufferLoader';
import type { EPGEntry } from './types';

type ProgramList = import('./ProgramList').ProgramList;
type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;

const MINIMUM_RELOAD_RATE = 15 * 60 * 1000; // Reload at least every 15 minutes.
const MAXIMUM_RELOAD_RATE = 60 * 1000; // Reload every 1 minute when no future program.
const MINIMUM_BUFFER_TIME = 2 * 60 * 1000; // Try to keep minimum 2 minutes of EPG buffer.
const RETRY_RELOAD_RATE = 15 * 1000; // Failed reloads will try again after 15 seconds.

const log = logging('ludo:epg:load');

/**
 * Load, and keep reloading as needed, the EPG for a live channel.
 */
export class EPGLoader {
  private player: ExtendedLudo;
  private loader: EPGLiveBufferLoader;
  private programList: ProgramList;
  private reloadTimeoutId?: number;
  private lastLoadTime: number = 0;
  private destroyed: boolean = false;

  constructor(player: ExtendedLudo, loader: EPGLiveBufferLoader, programList: ProgramList) {
    this.player = player;
    this.loader = loader;
    this.programList = programList;
    // tslint:disable-next-line:no-floating-promises
    this.reload();
  }

  destroy() {
    this.destroyed = true;
    this.clearReloadTimeout();
  }

  private async reload() {
    log('Reloading EPG');
    this.lastLoadTime = Date.now();
    let programs;

    try {
      programs = parse((await this.loader.load()).data);
    } catch (error) {
      log.warn(error);
      if (!this.destroyed) {
        this.setReloadTimeout(RETRY_RELOAD_RATE);
      }
      return;
    }

    if (!this.destroyed) {
      this.updateProgramList(programs);
      this.setReloadTimeout(this.timeToNextReload());
    }
  }

  private setReloadTimeout(timeout: number) {
    // Rough up the timeout value a little so that the clients will not align.
    // +- half the RETRY_RELOAD_RATE.
    const roughTimeout = Math.max(0, timeout + Math.floor(
      Math.random() * RETRY_RELOAD_RATE - RETRY_RELOAD_RATE / 2));

    log(`EPG will be reloaded in ${roughTimeout / 1000} seconds.`);
    this.reloadTimeoutId = window.setTimeout(this.reload.bind(this), roughTimeout);
  }

  private clearReloadTimeout() {
    if (this.reloadTimeoutId) {
      window.clearTimeout(this.reloadTimeoutId);
      this.reloadTimeoutId = 0;
    }
  }

  private timeToNextReload(): number {
    const timeSinceLastReload = Date.now() - this.lastLoadTime;

    // Normally, the next reload will happen according to the minimum rate.
    const scheduledReload = MINIMUM_RELOAD_RATE - timeSinceLastReload;

    // If that time has passed already, then reload now.
    if (scheduledReload <= 0) {
      return 0;
    }

    // Should we reload sooner than the schedule?
    // Check if the remaining EPG buffer is short or empty.
    const liveBufferEnd = this.player.convertTimeToLiveTime(this.player.duration());
    const remainingEPGTime = this.programList.getRemainingBufferTime(liveBufferEnd.getTime());

    log(`Have EPG data for another ${(remainingEPGTime || 0) / 1000} seconds.`);

    if (remainingEPGTime === null || remainingEPGTime - MINIMUM_BUFFER_TIME < scheduledReload) {
      return Math.max(
        0, // Reload no sooner than now.
        MAXIMUM_RELOAD_RATE - timeSinceLastReload, // Reload no sooner than the max rate.
        (remainingEPGTime || 0) - MINIMUM_BUFFER_TIME // Reload no sooner than the buffer really needs.
      );
    }

    // Otherwise, go for the schedule.
    return scheduledReload;
  }

  private updateProgramList(programs: EPGEntry[]) {
    this.programList.set(programs);
    this.player.set('epg', programs);
    this.player.emit(NrkEvents.EPG_UPDATED, programs);
  }
}
