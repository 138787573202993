import { LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import { classToggle } from '../../dom';
import playerMethods from '../../ui/playerMethods';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

const LIVE_MEDIA_TYPES = [LudoMediaTypes.LIVE, LudoMediaTypes.DVR];
export const PLAYING_LIVE_DURATION = 50;

export default (ui: LudoUIType) => {
  require('./live-button.styl');

  return (player: ExtendedLudo) => {
    const button = ui.element.querySelector<HTMLElement>('button[name="live"]')!;

    function exposePositionState() {
      const isLiveOrDVR = LIVE_MEDIA_TYPES.indexOf(player.mediaType()) !== -1;
      button[isLiveOrDVR ? 'removeAttribute' : 'setAttribute']('hidden', '');

      if (isLiveOrDVR) {
        const isPositionAtEndOfBuffer = player.currentTime() > player.duration() - PLAYING_LIVE_DURATION;
        const isPlayingLive = isPositionAtEndOfBuffer && !player.isPaused();

        button.setAttribute('aria-label', isPlayingLive ? 'Du følger nå direkte' : 'Trykk for å gå frem til nåværende tidspunkt');
        classToggle(button, 'ludo-livebutton--playing-live', isPlayingLive);
      }
    }

    ui.on('click.live', playerMethods(player).playLive);
    player.on(LudoEvents.PREPARED, exposePositionState);
    player.on(LudoEvents.SEEKED, exposePositionState);
    player.on(LudoEvents.BUFFEREND, exposePositionState);
    player.on(LudoEvents.PLAYING, exposePositionState);
    player.on(LudoEvents.PAUSE, exposePositionState);
  };
};
