import { parse as parseDuration, toSeconds } from 'iso8601-duration';
import { EPGLiveBuffer } from '../APIClient/psapi/response/EPGLiveBuffer';
import { EPGEntry } from './types';
import { TimeWithTZ } from '../utilities/TimeWithTZ';
import { isEuropeanSummerTime } from '@nrk/european-summer-time';


export function parse(data: EPGLiveBuffer = []): EPGEntry[] {
  const programs: EPGEntry[] = data.map((program) => {

    const plannedStartWithTZ = TimeWithTZ.parse(program.plannedStart)!;
    const actualStartWithTZ = TimeWithTZ.parse(program.actualStart);

    // New values for these properties. Note that duration changes type from
    // string to number.
    const duration = toSeconds(parseDuration(program.duration));
    const plannedStart = plannedStartWithTZ.toModernPSAPIFormat();
    const actualStart = (actualStartWithTZ || plannedStartWithTZ).toModernPSAPIFormat();

    // New properties.
    const actualStartUTC = (actualStartWithTZ || plannedStartWithTZ).utc;
    const actualEndUTC = actualStartUTC + duration * 1000;

    return {
      ...program,
      duration,
      plannedStart,
      actualStart,
      actualStartUTC,
      actualEndUTC
    };
  });

  // Add gap entries.
  let i = programs.length;

  const hourInMs = 3600000;
  const dayInMs = hourInMs * 24;
  const dayStartUTC = Math.floor(Date.now() / dayInMs) * dayInMs;
  const dayEndUTC = dayStartUTC + dayInMs;

  // 00:00 UTC is either 01:00 or 02:00 in Norway. We want 00:00 in Norway,
  // which is 23:00 or 22:00 UTC.
  const dayStartNorwayUTC = dayStartUTC - (isEuropeanSummerTime(dayStartUTC) ? hourInMs * 2 : hourInMs);
  const dayEndNorwayUTC = dayEndUTC - (isEuropeanSummerTime(dayEndUTC) ? hourInMs * 2 : hourInMs);

  if (i === 0) {
    return [{
      actualStartUTC: dayStartNorwayUTC,
      actualEndUTC: dayEndNorwayUTC,
      duration: (dayEndNorwayUTC - dayStartNorwayUTC) / 1000 // 23-25 hours.
    }]
  }

  const firstActualStartUTC = programs[0].actualStartUTC;
  const lastActualEndUTC = programs[programs.length - 1].actualEndUTC;

  if (firstActualStartUTC > dayStartNorwayUTC) {
    const duration = (firstActualStartUTC - dayStartNorwayUTC) / 1000;
    programs.unshift({
      actualStartUTC: dayStartNorwayUTC,
      actualEndUTC: firstActualStartUTC,
      duration
    });
  }

  if (lastActualEndUTC < dayEndNorwayUTC) {
    const duration = (dayEndNorwayUTC - lastActualEndUTC) / 1000;

    if (duration < 1) {
      // Don't create a <1s gap. Just stretch out the last program.
      programs[programs.length - 1].actualEndUTC = dayEndNorwayUTC;
    } else {
      programs.push({
        actualStartUTC: lastActualEndUTC,
        actualEndUTC: dayEndNorwayUTC,
        duration
      });
    }
  }

  while (--i > 0) {
    if (programs[i].actualStartUTC > programs[i - 1].actualEndUTC) {
      const gapStartUTC = programs[i - 1].actualEndUTC;
      const gapEndUTC = programs[i].actualStartUTC;
      const gapDuration = (gapEndUTC - gapStartUTC) / 1000;

      if (gapDuration < 1) {
        // Don't create a <1s gap. Just stretch out the program.
        programs[i - 1].actualEndUTC = gapEndUTC;
      } else {
        programs.splice(i, 0, {
          actualStartUTC: gapStartUTC,
          actualEndUTC: gapEndUTC,
          duration: gapDuration
        });
      }
    }
  }

  return programs;
}
