/**
 * Loader for /playback/manifest/{type}/{id}.
 */

import { PathLoader } from '../PathLoader';

type APIClient = import('../types').APIClient;
type PlaybackManifest = import('./response/IPlaybackManifest').IPlaybackManifest;

export class PlaybackManifestLoader extends PathLoader<PlaybackManifest> {
  constructor(client: APIClient, path: string) {
    super(client, path);
  }
}
