/* global process */
import merge from 'lodash/merge';
import setValue from 'lodash/set';
import logger from 'bows';

import {
  accessibilityButton,
  airplayButton,
  audioPoster,
  backwardButton,
  centerPlayButton,
  controller,
  countdownOverlay,
  debugOverlay,
  forwardButton,
  fullscreenButton,
  helpOverlay,
  liveButton,
  navKeys,
  notificationCenter,
  pictureInPictureButton,
  playnextButton,
  playpauseButton,
  scrubber,
  skipOverlay,
  spinner,
  subtitles,
  theaterModeButton,
  volumeButton
} from '../ui';

import { LudoErrorMessageHandler } from '@nrk/ludo-core';

import snapshotBlacklist from './snapshotBlacklist';
import {
  LudoPlayerFeatures,
  LudoPlayerOptions,
  LudoPlayerOptionsArgument,
  LudoPlayerType,
  NamedConfiguration
} from './interfaces';
import { LudoUIType } from '../ui/ui/LudoUI';

export const PLAYER_TYPES = {
  video: 'video',
  audio: 'audio'
};

export const DEFAULT_PLAYER_TYPE: LudoPlayerType = PLAYER_TYPES.video as LudoPlayerType;

const availableFeatures: LudoPlayerFeatures = {
  poster: true,
  help: true,
  volume: true,
  subtitles: true,
  fullscreen: true,
  chromecast: true,
  airplay: true,
  analytics: true,
  livebutton: true,
  nextbutton: false,
  audioPoster: false,
  back15sec: false,
  forward15sec: false,
  liveEpg: true,
  navKeys: true,
  pictureInPicture: true,
  theaterMode: false,
  debugOverlay: false,
  liveIndicator: false,
  skipIntro: false,
  skipCredits: false,
  disableUI: false
};

export const defaultFeatures = (playerType = DEFAULT_PLAYER_TYPE): LudoPlayerFeatures => {
  if (playerType === PLAYER_TYPES.audio) {
    return {
      ...availableFeatures,
      help: false,
      poster: false,
      subtitles: false,
      fullscreen: false,
      livebutton: false,
      audioPoster: true,
      back15sec: true,
      forward15sec: true,
      pictureInPicture: false,
      theaterMode: false
    };
  }

  return { ...availableFeatures };
};

function isHostnameInBlacklist(hostname?: string, blacklist?: (string | RegExp)[]) {
  if (!blacklist || !hostname) {
    return false;
  }
  return blacklist.some((loc) => {
    if (typeof loc === 'string') {
      return loc === hostname;
    }
    if (loc instanceof RegExp) {
      return loc.test(hostname);
    }
    return false;
  });
}

export function getDefaultAcceptHeader(
  { location = window.location, playerType }:
    { location?: { hostname: string }, playerType?: LudoPlayerType } = {},
  noSnapshotHostnames: (string | RegExp)[] = snapshotBlacklist) {

  const { hostname }: { hostname?: string } = location || {};

  let useSnapshotApi = true;

  const parts = [
    'application/vnd.nrk.psapi+json',
    'version=9',
    'ludo-client=true'
  ];

  if (isHostnameInBlacklist(hostname, noSnapshotHostnames)) {
    useSnapshotApi = false;
  }

  if (useSnapshotApi) {
    parts.push('psapi=snapshot');
  }

  return parts.join('; ');
}

export const defaultOptions = (playerType: LudoPlayerType = DEFAULT_PLAYER_TYPE): LudoPlayerOptions => {
  const isVideoPlayer = playerType === PLAYER_TYPES.video;
  const isAudioPlayer = playerType === PLAYER_TYPES.audio;

  return {
    autoplay: false,
    autoadvance: false,
    controls: true,
    loop: true,
    mute: false,
    theaterMode: false,
    subtitles: true,
    thumbnails: isVideoPlayer ? 'dvr' : false,
    'pinned-control-overlay': isAudioPlayer,
    adapter: null, // preferred adapter
    // apiBaseUrl: Deprecated. Use apiClient.domain.
    // apiAccept: Deprecated. Use apiClient.headers.Accept.
    apiClient: {
      domain: 'psapi.nrk.no',
      headers: {
        Accept: getDefaultAcceptHeader({ playerType })
      },
      params: {}
    },
    analytics: {
      sourceId: null,
      userId: null
    },
    userId: undefined,
    chromecast: {
      enabled: false,
      receiverApplicationId: '3AEDF8D1' // prod
    },
    playerType,
    poster: {
      shade: true,
      title: true,
      duration: true,
      subtitle: true
    },
    features: defaultFeatures(playerType),
    style: isAudioPlayer ? 'audio' : undefined,
    videoPlayerAspectRatio: '16:9',
    debug: false,
    debugevents: false,
    use: []
  };
};

function additionalOptions() {
  return {
    versions: process.env.versions,
    version: process.env.version
  };
}

function convertLegacyOptions(options: LudoPlayerOptionsArgument) {
  const log = logger('ludo:deprecated');

  if (options.apiBaseUrl) {
    log('Option apiBaseUrl is deprecated. Use apiClient.domain instead.');
    setValue(options, 'apiClient.domain',
      options.apiBaseUrl.replace(/(^https?:\/\/|\/.*?$)/g, ''));
    delete options.apiBaseUrl;
  }

  if (options.apiAccept) {
    log('Option apiAccept is deprecated. Use apiClient.headers.Accept instead.');
    setValue(options, 'apiClient.headers.Accept', options.apiAccept);
    delete options.apiAccept;
  }
}

function getNamedConfigurations(config?: NamedConfiguration) {
  switch (config) {
    case 'simple':
      return {
        playerType: 'video',
        poster: {
          shade: false,
          title: false,
          duration: false,
          subtitle: false
        }
      };

    case 'radio':
      return {
        playerType: 'audio',
        features: {
          audioPoster: false,
          livebutton: true
        },
        style: 'radio'
      };

    case 'audio':
      return {
        playerType: 'audio'
      };

    case 'podcast':
      return {
        playerType: 'audio',
        features: {
          chromecast: false
        }
      };

    default:
      return {};
  }
}

export const mergeOptions = (options: LudoPlayerOptionsArgument = {}): LudoPlayerOptions => {
  const config: LudoPlayerOptionsArgument = merge(
    getNamedConfigurations(options.config),
    options
  );

  const {
    playerType = DEFAULT_PLAYER_TYPE
  } = config;

  convertLegacyOptions(config);

  return merge(
    defaultOptions(playerType),
    additionalOptions(),
    config
  );
};

export const initUIComponents = (options: LudoPlayerOptions = {} as LudoPlayerOptions, ui: LudoUIType) => {
  const { theaterMode: theaterModeInitialState, features = {} as LudoPlayerFeatures } = options;

  const use: ((...args: any[]) => any)[] = [
    spinner(ui),
    controller(ui),
    scrubber(ui),
    playpauseButton(ui),
    centerPlayButton(ui),
    notificationCenter(ui),
    countdownOverlay(ui),
    LudoErrorMessageHandler
  ];

  if (features.livebutton) {
    use.push(liveButton(ui));
  }

  if (features.audioPoster) {
    use.push(audioPoster(ui));
  }

  if (features.back15sec) {
    use.push(backwardButton(ui));
  }

  if (features.forward15sec) {
    use.push(forwardButton(ui));
  }

  if (features.nextbutton) {
    use.push(playnextButton(ui));
  }

  if (features.help) {
    use.push(helpOverlay(ui));
  }

  if (features.volume) {
    use.push(volumeButton(ui));
  }

  if (features.subtitles) {
    use.push(accessibilityButton(ui));
    use.push(subtitles(ui));
  }

  if (features.fullscreen) {
    use.push(fullscreenButton(ui));
  }

  if (features.airplay) {
    use.push(airplayButton(ui));
  }

  if (features.pictureInPicture) {
    use.push(pictureInPictureButton(ui));
  }

  if (features.theaterMode) {
    use.push(theaterModeButton(ui, {initialState: theaterModeInitialState}));
  }

  if (features.debugOverlay) {
    use.push(debugOverlay(ui));
  }

  if (features.navKeys) {
    use.push(navKeys(ui));
  }

  const { skipIntro, skipCredits } = features;
  if (skipIntro || skipCredits) {
    use.push(skipOverlay(ui, { skipIntro, skipCredits }));
  }

  return use;
};
