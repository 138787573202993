import { AppInsights } from 'applicationinsights-js';
import { ServiceID } from '@nrk/snowplow-web';

type AppInsightsConfig = typeof AppInsights.config;

export interface SiteConfig {
  hostname: string;
  pathname?: string;
  data: SiteConfigData;
}

export interface SiteConfigData {
  name?: string;
  environment?: string;
  isVideoEmbed?: boolean;
  options: SiteOptions;
}

export interface SiteOptions {
  applicationInsights?: AppInsightsConfig;
  snowplow: {
    serviceId: ServiceID;
  }
}

export const siteConfigs: SiteConfig[] = [
  {
    hostname: '^[^\\.]*\\btv\\b[^\\.]*\\.nrk\\.no$',
    data: {
      name: 'NRK TV',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    hostname: '^[^\\.]*\\bradio\\b[^\\.]*\\.nrk\\.no$',
    data: {
      name: 'NRK Radio',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrkradio
        }
      }
    }
  },
  {
    hostname: '^[^\\.]*\\btv\\b[^\\.]*\\.nrksuper\\.no$',
    data: {
      name: 'NRK Super TV',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrksuper
        }
      }
    }
  },
  {
    hostname: '^(.+\\.)?nrksuper\\.no$',
    data: {
      name: 'NRK Super',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrksuper
        }
      }
    }
  },
  {
    hostname: '^(.+\\.)?p3\\.no$',
    data: {
      name: 'NRK P3',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrkp3
        }
      }
    }
  },
  {
    hostname: '^(.+\\.)?nrkbeta\\.no$',
    data: {
      name: 'NRK beta',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrkbeta
        }
      }
    }
  },
  {
    hostname: '^(.+\\.)?yr\\.no$',
    data: {
      name: 'Yr',
      options: {
        snowplow: {
          serviceId: ServiceID.Yr
        }
      }
    }
  },
  {
    hostname: '\\b(ark|felles\\.ds|preview|fil|kubeint|snutt)\\.nrk\\.no$',
    data: {
      environment: 'internal',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrkno
        }
      }
    }
  },
  {
    hostname: '^static\\.nrk\\.no$',
    pathname: '^/dev/',
    data: {
      environment: 'dev',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    hostname: '(www|static)\\.nrk\\.no$',
    pathname: '^(/embed/.*|.*/video-embed(\\.html)?)$',
    data: {
      isVideoEmbed: true,
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    hostname: '\\.nrk\\.no$',
    data: {
      name: 'NRK.no',
      options: {
        applicationInsights: {
          samplingPercentage: 10
        },
        snowplow: {
          serviceId: ServiceID.Nrkno
        }
      }
    }
  },
  {
    hostname: '^localhost$',
    data: {
      environment: 'test',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    // Internal domains without dots.
    hostname: '^[A-Za-z0-9]+$',
    data: {
      environment: 'internal',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    // NRK IP ranges.
    hostname: '^160\\.6[78]\\.\\d+\\.\\d+$',
    data: {
      environment: 'internal',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    // Assume all IP addresses are for testing.
    hostname: '^\\d+\\.\\d+\\.\\d+\\.\\d+$',
    data: {
      environment: 'test',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrktv
        }
      }
    }
  },
  {
    hostname: '^nrkno\\.github\\.io$',
    data: {
      environment: 'test',
      options: {
        snowplow: {
          serviceId: ServiceID.Nrkno
        }
      }
    }
  }
];
