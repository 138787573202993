import bows from 'bows';
import defaultNrkMediaLogger from './nrkMediaLogger';
import { ExtendedLudo, AnalyticsConfig } from '../../ludo/interfaces';
import * as snowplow from '@nrk/snowplow-web/snowplow-bundle.js';
import { SharedWebPageContextPlugin } from '@nrk/snowplow-web/plugins/shared-web-page-context-plugin';
import { NrkBaseTracker } from '@nrk/snowplow-web';

export default function AudienceTracker(
  analyticsConfig: AnalyticsConfig,
  container: {
    debug?: typeof bows;
    nrkMediaLogger?: typeof defaultNrkMediaLogger;
  } = {}
) {
  const { debug = bows, nrkMediaLogger = defaultNrkMediaLogger } = container;

  return (player: ExtendedLudo) => {
    const userId = analyticsConfig.userId || undefined;

    // Config prepared in src/js/ludo/index.ts
    const snowplowConfig = analyticsConfig.snowplow;
    if (snowplowConfig === undefined) {
      throw new Error('Snowplow config missing in analytics config.');
    }

    if (snowplowConfig.trackPageView) {
      const pageViewTracker = new NrkBaseTracker(snowplow, {
        ...snowplowConfig,
        trackerId: 'ludo-page-view-tracker',
        appVersion: player.get('version'),
        appBuild: player.get('version'),
        disableLocalStorage: true,
        plugins: [new SharedWebPageContextPlugin()]
      });
      if (userId !== undefined) {
        pageViewTracker.setUser(userId);
      }
      pageViewTracker.trackPageView();
    }

    nrkMediaLogger(player, {
      snowplow,
      snowplowConfig,
      userId,
      debug
    });
  };
}
